import leadService from "@/services/lead.service";

const actions = {
  createLead({commit}, payload) {
    return leadService.createLead(commit, payload);
  },
};

export const leads = {
  namespaced: true,
  actions,
};
