import http from "@/services/http.service";
import {MEMBERS, SHARE_MEMBER} from "@/constants/apiUrls";

const members = async (commit) => {
  const response = await http.get(MEMBERS);
  commit("setMembers", response.data);
};

const shareMember = async (commit, payload) => {
  await http.post(SHARE_MEMBER, payload);
};

export default {
  members,
  shareMember,
};
