import http from "@/services/http.service";
import {MESSAGES} from "@/constants/apiUrls";

const getMessages = async (commit, payload) => {
  const response = await http.get(`${MESSAGES}/${payload}`);
  commit("setMessages", response.data);
};

const createMessage = async (commit, payload) => {
  await http.post(MESSAGES, payload);
};

export default {
  getMessages,
  createMessage,
};
