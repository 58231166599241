<template>
  <v-container class="pa-0 fill-height" fluid>
    <v-row no-gutters class="fill-height">
      <v-col
        primary
        cols="12"
        sm="6"
        md="5"
        class="d-inline green darken-4 white--text bg-image"
      >
        <div class="pa-6 pa-md-12 fill-height d-flex flex-wrap flex-w">
          <div class="w-100">
            <v-img src="../assets/logo.svg" width="350"></v-img>
          </div>

          <v-card
            elevation="0"
            class="d-inline green darken-4 white--text login-mid"
          >
            <h1 class="text-left mb-4">{{ $t('login.services') }}</h1>
            <div class="d-flex flex-wrap">
              <div class="flex-item">
                <div>
                  <v-icon>mdi-post</v-icon>
                  <span>{{ $t('login.post') }}</span>
                </div>
              </div>
              <div class="flex-item">
                <div>
                  <v-icon>mdi-credit-card-search</v-icon>
                  <span>{{ $t('login.vinSearch') }}</span>
                </div>
              </div>
              <div class="flex-item">
                <div>
                  <v-icon>mdi-car</v-icon>
                  <span>{{ $t('login.carHistoryReport') }}</span>
                </div>
              </div>
              <div class="flex-item">
                <div>
                  <v-icon>mdi-receipt</v-icon>
                  <span>{{ $t('login.invoicing') }}</span>
                </div>
              </div>
              <div class="flex-item">
                <div>
                  <v-icon>mdi-text-box-search</v-icon>
                  <span>{{ $t('login.inventory') }}</span>
                </div>
              </div>
              <div class="flex-item">
                <div>
                  <v-icon>mdi-cogs</v-icon>
                  <span>{{ $t('login.interchange') }}</span>
                </div>
              </div>
            </div>
          </v-card>
          <div class="text-center login-foot align-self-end justify-center">
            &copy; 2021 Redline All right reserved.
            <button @click="$router.push('/terms-condition')">
              {{ $t('login.term&condition') }}
            </button>
          </div>
        </div>
      </v-col>
      <v-col
        primary
        cols="12"
        sm="6"
        md="7"
        class="d-inline light-green lighten-5"
      >
        <div
          class="pa-6 pa-sm-4 pa-md-12 fill-height d-flex flex-direction: column justify-center"
        >
          <v-container class="pa-0 fill-height" fluid>
            <v-row no-gutters class="fill-height">
              <v-col primary cols="12" sm="9" md="6" class="ma-auto">
                <v-form>
                  <v-text-field
                    v-model.trim="$v.email.$model"
                    solo
                    :error-messages="emailError"
                    :label="$t('login.username')"
                    append-icon="mdi-account-outline"
                    color="green  darken-4"
                  ></v-text-field>
                  <v-text-field
                    v-model.trim="$v.password.$model"
                    :error-messages="passwordError"
                    type="password"
                    solo
                    :label="$t('login.password')"
                    append-icon="mdi-lock-outline"
                    color="green darken-4 "
                  ></v-text-field>
                  <v-checkbox
                    class="mt-0"
                    color="green darken-4 "
                    v-model="rememberMe"
                    :label="$t('login.rememberMe')"
                    required
                  ></v-checkbox>
                  <v-btn
                    @click="submit"
                    block
                    color="green darken-4 green-gradient"
                    dark
                    x-large
                  >
                    {{ $t('login.login') }}
                  </v-btn>
                </v-form>
                <v-divider class="mt-8"><span>OR</span></v-divider>
                <v-row align="center">
                  <v-col primary cols="12" sm="6">
                    <div class="text-center mt-10">
                      <v-img
                        src="../assets/telephone.svg"
                        width="80"
                        class="mb-5 ma-auto"
                      >
                      </v-img>
                      <p class="call-phone">
                        {{ $t('login.helpCall') }}
                        <br/>
                        <a href="tel:714-334-5505">714-334-5505</a>
                      </p>
                    </div>
                  </v-col>
                  <v-col primary cols="12" sm="6">
                    <div>
                      <v-btn
                        block
                        color="green darken-4  green-gradient"
                        dark
                        medium
                        @click="$router.push('/leads')"
                      >
                        {{ $t('login.newMemberClick') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <template>
            <div class="text-center">
              <v-dialog
                v-model="dialog"
                width="500"
              >
                <v-card>
                  <v-card-title class="headline grey lighten-2">
                    {{ $t('login.forceLogoutDialogTitle') }}
                  </v-card-title>

                  <v-card-text>
                    <div class="pt-4">
                      <h3>
                        {{ $t('login.forceLogoutDialogMessage') }}
                      </h3>
                    </div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      height="50"
                      medium
                      @click="dialog = false"
                      dense
                      class="green darken-4 green-gradient white--text"
                      elevation="0"
                    >
                      {{ $t('login.cancel') }}
                    </v-btn>

                    <v-btn
                      height="50"
                      medium
                      @click="forceLogin"
                      dense
                      class="green darken-4 green-gradient white--text"
                      elevation="0"
                    >
                      {{ $t('login.forceLogout') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import {required} from "vuelidate/lib/validators";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      force_login: false,
      rememberMe: false,
      dialog: false,
      apiErrorMessage: null,
      loggedInMessage: null,
      errors: {
        email: [],
        password: [],
      },
    };
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
    },
  },
  computed: {
    emailError() {
      let error = "";
      if (this.$v.email.$dirty && !this.$v.email.required) {
        error = this.$t("requiredValidation", {field: this.$t('login.email')});
      }
      if (this.errors && this.errors.email) {
        error = this.errors.email;
      }

      return error;
    },
    passwordError() {
      let error = "";
      if (this.$v.password.$dirty && !this.$v.password.required) {
        error = this.$t("requiredValidation", {field: this.$t('login.password')});
      }
      if (this.errors && this.errors.password) {
        error = this.errors.password;
      }

      return error;
    },
  },

  methods: {
    ...mapActions({
      login: "user/login",
    }),
    async submit() {
      this.$v.$touch();
      this.dialog = false;
      if (this.email && this.password) {
        this.$store.commit("setLoader", true);
        try {
          await this.login({
            email: this.email,
            password: this.password,
            force_login: this.force_login,
          });
          this.$router.push("/dashboard");
        } catch ({message}) {
          if (message.logged_in) {
            this.loggedInMessage = message.logged_in[0];
            this.dialog = true;
          }
          this.errors.email = message.email;
          this.errors.password = message.password;
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },

    forceLogin() {
      try {
        this.force_login = true;
        this.submit();
      } finally {
        this.force_login = false;
        this.dialog = false;
        this.rememberMe = false;
      }
    },
  },
};
</script>
