import http from "@/services/http.service";
import { POST_THREADS } from "@/constants/apiUrls";

const getPostThreads = async (commit) => {
  const response = await http.get(POST_THREADS);
  commit("setPostThreads", response.data);
};

const getPostThread = async (commit, payload) => {
  const response = await http.get(`${POST_THREADS}/${payload}`);
  commit("setPostThread", response.data);
};

export default {
  getPostThread,
  getPostThreads,
};
