import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Lead from "../views/Lead.vue";
import TermCondition from "../views/TermCondition.vue";
import store from "@/store";
import {
  loadLanguageAsync
} from "@/plugins/i18n";
import authUtility from "@/utils/auth.utility";
import {
  MEMBERS_DIRECTORY,
  CAR_HISTORY_REPORT,
  VIN_SEARCH,
  INTERCHANGE,
  SEARCH_INVENTORY,
  INVOICE,
  DMV_PROCESSING,
  CAR_AUCTION,
  TRADE_CAR_PARTS,
  SELLING_EBAY,
  POSTING,
} from "@/constants/services";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/terms-condition",
    name: "TermCondition",
    component: TermCondition,
  },
  {
    path: "/leads",
    name: "Leads",
    component: Lead,
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    children: [
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
      },
      {
        path: "",
        name: "MembersDirectory",
        component: () => import("../views/MembersDirectory.vue"),
        meta: {
          service: MEMBERS_DIRECTORY,
        },
      },
      {
        path: "car-history",
        name: "CarHistory",
        component: () => import("../views/CarHistory.vue"),
        meta: {
          service: CAR_HISTORY_REPORT,
        },
      },
      {
        path: "car-history-result",
        name: "CarHistoryResult",
        component: () => import("../views/CarHistoryResult.vue"),
        meta: {
          service: CAR_HISTORY_REPORT,
        },
      },
      {
        path: "vin-search",
        name: "VinSearch",
        component: () => import("../views/VinSearch.vue"),
        meta: {
          service: VIN_SEARCH,
        },
      },
      {
        path: "vin-search-result",
        name: "VinSearchResult",
        component: () => import("../views/VinSearchResult.vue"),
        meta: {
          service: VIN_SEARCH,
        },
      },
      {
        path: "message-center",
        name: "MessageCenter",
        component: () => import("../views/MessageCenter.vue"),
        meta: {
          service: POSTING,
        },
      },
      {
        path: "post-thread/:postThreadId/messages",
        name: "ThreadMessages",
        component: () => import("../views/Messages.vue"),
        meta: {
          service: POSTING,
        },
      },
      {
        path: "interchange",
        name: "InterChange",
        component: () => import("../views/InterChange.vue"),
        meta: {
          service: INTERCHANGE,
        },
      },
      {
        path: "interchange-result",
        name: "InterChangeResults",
        component: () => import("../views/InterChangeResult.vue"),
        meta: {
          service: INTERCHANGE,
        },
      },
      {
        path: "serach-inventory",
        name: "SearchInventory",
        component: () => import("../views/SearchInventory.vue"),
        meta: {
          service: SEARCH_INVENTORY,
        },
      },
      {
        path: "billing-invoice",
        name: "BillingInvoice",
        component: () => import("../views/BillingInvoice.vue"),
        meta: {
          service: INVOICE,
        },
      },
      {
        path: "dmv-processing",
        name: "DMVProcessing",
        component: () => import("../views/DMVProcessing.vue"),
        meta: {
          service: DMV_PROCESSING,
        },
      },
      {
        path: "car-auctions",
        name: "CarAuctions",
        component: () => import("../views/CarAuctions.vue"),
        meta: {
          service: CAR_AUCTION,
        },
      },
      {
        path: "trade-car-parts",
        name: "TradeCarParts",
        component: () => import("../views/TradeCarParts.vue"),
        meta: {
          service: TRADE_CAR_PARTS,
        },
      },
      {
        path: "selling-ebay",
        name: "SellingEbay",
        component: () => import("../views/SellingEbay.vue"),
        meta: {
          service: SELLING_EBAY,
        },
      },
      {
        path: "unauthorized",
        name: "UnAuthorized",
        component: () => import("../views/UnAuthorized.vue"),
      },
      {
        path: "forbidden",
        name: "Forbidden",
        component: () => import("../views/Forbidden.vue"),
      },
      {
        path: "404-error",
        name: "404Error",
        component: () => import("../views/404Error.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const lang = localStorage.getItem("lang") ?
    localStorage.getItem("lang") :
    process.env.VUE_APP_LANGUAGE;
  await loadLanguageAsync(lang);
  const publicPages = ["Login", "Leads","TermCondition"];
  if (to.name == "Login" && store.getters["user/isLoggedIn"]) {
    next({
      path: "/dashboard",
    });
  } else if (
    !publicPages.includes(to.name) &&
    !store.getters["user/isLoggedIn"]
  ) {
    next({
      name: "Login",
    });
  } else if (
    to.meta.service &&
    !authUtility.hasAccess(
      to.meta.service,
      store.getters["user/getUserServices"]
    )
  ) {
    next({
      name: "UnAuthorized",
    });
  } else {
    next();
  }
});

export default router;