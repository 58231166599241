const ACCESS_TOKEN = "access_token";
const USER_DATA = "user_data";

const setAccessToken = (accessToken) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
};

const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN);
};

const setUserData = (userData) => {
    return localStorage.setItem(USER_DATA, JSON.stringify(userData));
};

const getUserData = () => {
    return JSON.parse(localStorage.getItem(USER_DATA));
};

const getServices = () => {
    return localStorage.getItem(USER_DATA) && JSON.parse(localStorage.getItem(USER_DATA)).services;
};

const clear = () => {
    localStorage.clear();
}

export default {
    setAccessToken,
    getAccessToken,
    setUserData,
    getUserData,
    getServices,
    clear
}
