<template>
  <v-app>
    <Loading :active="loader" loader="dots" color="#4CAF50" />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Loading: Loading,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      loader: "getLoaderStatus",
    }),
  },
};
</script>
