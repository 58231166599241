import http from "@/services/http.service";
import {LEADS} from "@/constants/apiUrls";

const createLead = async (commit, payload) => {
  await http.post(LEADS, payload);
};

export default {
  createLead,
};
