import http from "@/services/http.service";
import { VIN_SEARCH_REPORT, SEND_PDF_TO_MAIL } from "@/constants/apiUrls";

const vinSearchReport = async (commit, payload) => {
    const response = await http.post(VIN_SEARCH_REPORT, payload);
    commit("setVinSearchReport", response.data);
    commit("setVin", payload.vin);
    commit("setPdfPath", response.data.pdf_path);
    commit("setPdfUrl", response.data.pdf_url);
};

const sendPdfToMail = async (commit, payload) => {
    await http.post(SEND_PDF_TO_MAIL, payload);
};

export default {
    vinSearchReport,
    sendPdfToMail
};
