import messageService from "@/services/messages.service";

const state = {
  messages: [],
};

const actions = {
  getMessages({commit}, payload) {
    return messageService.getMessages(commit, payload);
  },

  createMessages({commit}, payload) {
    return messageService.createMessage(commit, payload);
  },
};

const mutations = {
  setMessages(state, response) {
    state.messages = response.data;
  },
};

const getters = {
  getMessages(state) {
    return state.messages;
  },
};

export const messages = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
