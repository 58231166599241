import memberService from "@/services/member.service";

const state = {
  members: [],
};

const actions = {
  getMembers({commit}) {
    return memberService.members(commit);
  },

  shareMember({commit}, payload) {
    return memberService.shareMember(commit, payload);
  },
};

const mutations = {
  setMembers(state, response) {
    state.members = response.data;
  },
};

const getters = {
  getMembers(state) {
    return state.members;
  },
};

export const members = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
