import Vue from "vue";
import { i18n } from "./plugins/i18n";
import { VueTelInput } from "./plugins/vue-tel-input";
import { Vuelidate } from "./plugins/vuelidate";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./SCSS/main.scss";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

Vue.directive("to-us-format", {
  update: function (el) {
    const handler = function (e) {
      if (e.target.value) {
        const formattedValue = e.target.value
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !formattedValue[2]
          ? formattedValue[1]
          : "(" +
            formattedValue[1] +
            ") " +
            formattedValue[2] +
            (formattedValue[3] ? "-" + formattedValue[3] : "");
      }
    };
    el.addEventListener("input", handler);
  },
});

Vue.config.productionTip = false;
Vue.prototype.moment = moment;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueTelInput,
  Vuelidate,
  render: (h) => h(App),
}).$mount("#app");
