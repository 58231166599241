import carHistoryService from "@/services/carHistory.service";

const state = {
  carHistoryReport: [],
};

const actions = {
  getCarHistoryReport({commit}, payload) {
    return carHistoryService.getCarHistoryReport(commit, payload);
  },
};

const mutations = {
  setCarHistoryReport(state, response) {
    state.carHistoryReport = response.data;
  }
};

const getters = {
  getCarHistoryReport(state) {
    return state.carHistoryReport;
  }
};

export const carHistory = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
