import http from "@/services/http.service";
import tokenService from "@/services/token.service";
import { AUTH_TOKEN, LOGOUT, AUTH_TOKEN_REFRESH, PROFILE, UPDATE_PROFILE } from "@/constants/apiUrls";

const login = async (commit, payload) => {
  const response = await http.post(AUTH_TOKEN, payload);
  commit("loginSuccess", response.data);
  await profile(commit);
};

const logout = async (commit) => {
  await http.post(LOGOUT);
  commit("logout");
};

const profile = async (commit) => {
  const response = await http.get(PROFILE);
  commit("setUser", response.data);
};

const updateProfile = async (commit, payload) => {
  const response = await http.put(UPDATE_PROFILE, payload);
  commit("setUser", response.data);
};

const refreshToken = async (commit) => {
  const response = await http.post(AUTH_TOKEN_REFRESH);
  http.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${tokenService.getAccessToken()}`;
  commit("loginSuccess", response.data);
};

export default {
  login,
  logout,
  refreshToken,
  profile,
  updateProfile,
};
