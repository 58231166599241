import vinService from "@/services/vinSearch.service";

const state = {
    vinSearchReport: [],
    vin: null,
    pdf_path: null,
    pdf_url: null,
};

const actions = {
    getVinSearchReport({commit}, payload) {
        return vinService.vinSearchReport(commit, payload);
    },

    sendPdfToMailAction({commit}, payload) {
        return vinService.sendPdfToMail(commit, payload);
    },
};

const mutations = {
    setVinSearchReport(state, response) {
        state.vinSearchReport = response.data;
    },

    setVin(state, vin) {
        state.vin = vin;
    },

    setPdfPath(state, path) {
        state.pdf_path = path;
    },

    setPdfUrl(state, url) {
        state.pdf_url = url;
    },
};

const getters = {
    getVinSearchReport(state) {
        return state.vinSearchReport;
    },
    getVin(state) {
        return state.vin;
    },

    getPdfPath(state) {
        return state.pdf_path;
    },

    getPdfUrl(state) {
        return state.pdf_url;
    }
};

export const vinSearchReport = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
