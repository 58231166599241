<template>
  <v-container class="pa-0 fill-height" fluid>
    <v-row no-gutters class="fill-height">
      <v-col
        primary
        cols="12"
        sm="6"
        md="5"
        class="d-inline green darken-4 white--text bg-image"
      >
        <div class="pa-6 pa-md-12 fill-height d-flex flex-wrap">
          <div>
            <v-img src="../assets/logo.svg" width="150"></v-img>
          </div>

          <v-card elevation="0" class="d-inline green darken-4 white--text">
            <h1>Hi There</h1>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam
              natus sit voluptas quas, dolore dolorum quae accusamus nemo ea
              iure magni, numquam inventore architecto recusandae suscipit.
              Laudantium sequi eveniet aut.
            </p>
          </v-card>
          <div class="text-center login-foot align-self-end justify-center">
            &copy; 2021 Loremipsum All right reserved.
          </div>
        </div>
      </v-col>
      <v-col
        primary
        cols="12"
        sm="6"
        md="7"
        class="d-inline light-green lighten-5"
      >
        <div
          class="pa-6 pa-sm-4 pa-md-12 fill-height d-flex flex-direction: column justify-center"
        >
          <v-container class="pa-0 fill-height" fluid>
            <ApiErrorMessage :message="apiErrorMessage"/>
            <v-row no-gutters class="fill-height">
              <v-col primary cols="12" sm="9" md="6" class="ma-auto">
                <v-form id="form">
                  <v-text-field
                    v-model="companyName"
                    solo
                    :label="$t('leads.companyName')"
                    append-icon="mdi-city"
                    color="green  darken-4"
                  ></v-text-field>
                  <v-text-field
                    v-model="representativeName"
                    solo
                    :label="$t('leads.representativeName')"
                    append-icon="mdi-account-outline"
                    color="green  darken-4"
                  ></v-text-field>
                  <v-text-field
                    v-model="representativePhoneNumber"
                    solo
                    :label="$t('leads.representativePhone')"
                    append-icon="mdi-cellphone"
                    color="green  darken-4"
                  ></v-text-field>
                  <v-btn
                    @click.prevent="submitLeadDetail"
                    block
                    color="green darken-4  green-gradient"
                    dark
                    x-large
                  >
                    {{ $t('leads.submit') }}
                  </v-btn>
                </v-form>
                <v-divider class="mt-8 mb-8"><span></span></v-divider>
                <v-row align="center">
                  <v-col primary cols="12" sm="6">

                  </v-col>
                  <v-col primary cols="12" sm="6">
                    <div>
                      <v-btn
                        href="/login"
                        block
                        color="green darken-4  green-gradient"
                        dark
                        medium
                      >
                        {{ $t('leads.login') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Lead",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      companyName: null,
      representativeName: null,
      representativePhoneNumber: null,
      apiErrorMessage: null,
    };
  },
  methods: {
    ...mapActions({
      createLead: "leads/createLead",
    }),
    async submitLeadDetail() {
      this.$store.commit("setLoader", true);
      try {
        await this.createLead({
          company_name: this.companyName,
          representative_name: this.representativeName,
          representative_phone_number: this.representativePhoneNumber,
        });
        this.companyName = null;
        this.representativeName = null;
        this.representativePhoneNumber = null;
      } catch ({message}) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
