import http from "@/services/http.service";
import { POST } from "@/constants/apiUrls";

const posts = async (commit) => {
    const response = await http.get(POST);
    commit("setPosts", response.data);
};

const createPost = async (commit, payload) => {
    await http.post(POST, payload);
};

export default {
    posts,
    createPost,
};
