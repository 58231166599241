import http from "@/services/http.service";
import {CAR_HISTORY} from "@/constants/apiUrls";

const getCarHistoryReport = async (commit, payload) => {
  const response = await http.post(CAR_HISTORY, payload);
  commit("setCarHistoryReport", response.data);
};

export default {
  getCarHistoryReport,
};
