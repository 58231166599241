import postService from "@/services/post.service";

const state = {
  posts: [],
};

const actions = {
  getPosts({commit}) {
    return postService.posts(commit);
  },

  createPost({commit}, payload) {
    return postService.createPost(commit, payload);
  },
};

const mutations = {
  setPosts(state, response) {
    state.posts = response.data;
  },
};

const getters = {
  getPosts(state) {
    return state.posts;
  },
};

export const posts = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
