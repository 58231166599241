import Vue from "vue";
import Vuex from "vuex";

import { user } from "./modules/user";
import { posts } from "./modules/post";
import { vinSearchReport } from "./modules/vinSearch";
import { messages } from "./modules/message";
import { postThread } from "./modules/postThread";
import { members } from "./modules/member";
import { leads } from "./modules/lead";
import { carHistory } from "./modules/carHistory";
import { carHistoryReport } from "./modules/carHistoryReport";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false
  },
  getters: {
    getLoaderStatus(state) {
      return state.loading;
    }
  },
  mutations: {
    setLoader(state, status) {
      state.loading = status
    }
  },
  actions: {},
  modules: {
    user,
    posts,
    vinSearchReport,
    messages,
    postThread,
    members,
    leads,
    carHistory,
    carHistoryReport,
  },
});
