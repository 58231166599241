export const AUTH_TOKEN = "api/auth/login";
export const AUTH_TOKEN_REFRESH = "api/auth/refresh";
export const LOGOUT = "api/auth/logout";
export const PROFILE = "api/auth/user";
export const UPDATE_PROFILE = "api/auth/profile";
export const POST = "api/posts";
export const VIN_SEARCH_REPORT = "api/vin-search";
export const MESSAGES = "api/messages";
export const MEMBERS = "api/members";
export const SHARE_MEMBER = "api/share-member";
export const POST_THREADS = "api/post-threads";
export const LEADS = "api/leads";
export const CAR_HISTORY = "api/vehicle-history";
export const CAR_HISTORY_REPORT = "api/vehicle-history-report";
export const SEND_PDF_TO_MAIL = "api/send-pdf-to-mail";
